import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'c9b8786f-b79e-4c61-8e74-f5b49e51616a',
    clientToken: 'pubf3562a1391179c859e9a22bd74a70725',
    site: 'datadoghq.com',
    service:'graydon-lofts',
    env:'prd',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'test',
        description: '',
        buttonText: 'Contact Us',
        buttonLink: 'mailto:contact@graydonlofts.com'
    }
]

class HomePortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="active-dark bg_color--11"> 
                {/* <Helmet pageTitle="Graydon Lofts" /> */}

                {/* Start Header Area  */}
                {/* <Header /> */}
                {/* End Header Area  */}
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--34" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            <div classname="image-sizing"><center><img class="image-sizing" src="assets/images/tea/logo.png"></img></center></div>
                                            <div><p className="description">Graydon Lofts<p></p>26 East Sixth Street, Cincinnati Ohio</p></div>
                                            <div className="slide-btn">
                                                <a className="btn-default btn-border btn-opacity" href="https://www.redfin.com/OH/Cincinnati/26-E-6th-St-Cincinnati-OH-45202/building/11714">Units for Sale in Building</a><p></p>
                                                <a className="btn-default btn-border btn-opacity" href="https://lbmanagement.appfolio.com/connect">Resident Login</a><p></p>
                                                <div className="d1"><a href={`${value.buttonLink}`}>Contact Us</a></div>
                                            </div>
                                            {/* <br></br><br></br><br></br><br></br><br></br><br></br><br></br> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}
        

                {/* Start Footer Area  */}
                {/* <FooterTwo /> */}
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                {/* <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div> */}
                {/* End Back To Top */}
            </div>
        )
    }
}
export default HomePortfolio;